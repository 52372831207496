import React from 'react';
import PropTypes from 'prop-types';
import City from './City';
import I18n from '../../i18n';
import { arrayInGroupsOf } from '../../util';

class CityList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.getGroupedCityRows = this.getGroupedCityRows.bind(this);
  }

  getGroupedCityRows() {
    const { cities } = this.props;
    const { filter } = this.state;

    const filteredCities = cities.filter((c) => {
      const normalizedFilter = filter.toLowerCase().trim();

      const cityMatch = c.name.toLowerCase().indexOf(normalizedFilter) >= 0;
      const techMatch = c.datapoints.filter(d => d.score > 0).some(dp => (
        dp.name.toLowerCase().indexOf(normalizedFilter) >= 0
      ));

      return cityMatch || techMatch;
    });

    const cityRows = arrayInGroupsOf(filteredCities, CityList.rowSize).map(group => (
      group.map(city => <City city={city} key={city.name} />)
    ));

    cityRows.forEach((row) => {
      const len = row.length;

      if (len !== CityList.rowSize) {
        const paddingLen = CityList.rowSize - len;

        let i = 0;

        for (i = 0; i < paddingLen; i += 1) {
          row.push(<div key={`padding-${i}`} className="column" />);
        }
      }
    });

    return cityRows;
  }

  handleFilterChange(e) {
    this.setState({ filter: e.target.value });
  }

  render() {
    const { filter } = this.state;

    const cityRows = this.getGroupedCityRows();

    return (
      <div>
        <div className="control has-icons-left">
          <input
            type="text"
            className="city-search input is-large"
            placeholder={I18n.t('search')}
            value={filter}
            onChange={this.handleFilterChange}
          />
          <span className="icon is-medium is-left" role="img" aria-label="search">
            🔎
          </span>
        </div>
        {cityRows.map(row => (
          <div key={row[0].key} className="columns">
            {row}
          </div>
        ))}
      </div>
    );
  }
}

CityList.rowSize = 3;

CityList.propTypes = {
  cities: PropTypes.array.isRequired, // eslint-disable-line
};

export default CityList;
