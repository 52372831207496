import React from 'react';
import ReactDOM from 'react-dom';
import HomeBannerLanguage from '../src/components/home/HomeBannerLanguage';
import CityList from '../src/components/home/CityList';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <HomeBannerLanguage languages={['Scala', 'Ruby', 'Java', 'Go', 'TypeScript', 'C++']} />,
    document.querySelector('.banner-language'),
  );

  const cityListElement = document.querySelector('.cities');
  const cities = JSON.parse(cityListElement.getAttribute('data-cities'));

  ReactDOM.render(
    <CityList cities={cities} />,
    cityListElement,
  );
});
