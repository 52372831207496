import React from 'react';
import PropTypes from 'prop-types';
import { sleep, randomInt } from '../../util';

class HomeBannerLanguage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLanguage: 0,
      cursorVisible: true,
      currentText: '',
      languageChanging: false,
    };

    this.toggleCursor.bind(this);
    this.cycleLanguage.bind(this);
    this.deleteCurrentLanguage.bind(this);
    this.deleteLetter.bind(this);
    this.setLanguage.bind(this);
    this.addLetter.bind(this);
  }

  componentDidMount() {
    setInterval(() => this.toggleCursor(), 500);
    setTimeout(() => this.cycleLanguage(), 5000);
  }

  setLanguage(language) {
    const { currentText } = this.state;

    if (currentText !== language) {
      return sleep(randomInt(100, 300))
        .then(() => this.addLetter(language))
        .then(() => this.setLanguage(language));
    }

    return Promise.resolve();
  }

  addLetter(language) {
    return new Promise((resolve) => {
      const { currentText } = this.state;
      this.setState({ currentText: language.slice(0, currentText.length + 1) }, resolve);
    });
  }

  deleteCurrentLanguage() {
    const { currentText } = this.state;

    if (currentText !== '') {
      return sleep(randomInt(100, 300))
        .then(() => this.deleteLetter())
        .then(() => this.deleteCurrentLanguage());
    }

    return Promise.resolve();
  }

  deleteLetter() {
    return new Promise((resolve) => {
      const { currentText } = this.state;
      this.setState({ currentText: currentText.slice(0, -1) }, resolve);
    });
  }

  cycleLanguage() {
    const { languages } = this.props;
    const { currentLanguage } = this.state;

    const nextIndex = (currentLanguage + 1) % languages.length;
    const newLanguage = languages[nextIndex];

    const languageChanging = true;
    const currentText = languages[currentLanguage];

    this.setState({ languageChanging, currentText }, () => {
      this.deleteCurrentLanguage()
        .then(() => this.setLanguage(newLanguage))
        .then(() => this.setState({ languageChanging: false, currentLanguage: nextIndex }, () => {
          setTimeout(() => this.cycleLanguage(), 5000);
        }));
    });
  }

  toggleCursor() {
    const { cursorVisible } = this.state;

    this.setState({ cursorVisible: !cursorVisible });
  }

  render() {
    const { languages } = this.props;
    const {
      currentLanguage,
      cursorVisible,
      currentText,
      languageChanging,
    } = this.state;

    const props = {};
    if (cursorVisible) {
      props.className = 'banner-language-cursor';
    } else {
      props.className = 'banner-language-no-cursor';
    }

    const language = languageChanging ? currentText : languages[currentLanguage];

    return (
      <span {...props}>
        {language}
      </span>
    );
  }
}

HomeBannerLanguage.propTypes = {
  languages: PropTypes.array.isRequired, // eslint-disable-line
};

export default HomeBannerLanguage;
