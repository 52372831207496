const translations = {
  en: {
    population: 'Population: ',
    search: 'Search...',
    thousandsSeparator: ',',
    taglineMore: 'more',
    taglineNoData: 'No data available',
    cityChartYAxisLabel: 'Number of Jobs',
    dateFormat: '%m/%d/%Y',
    numFormat: {
      decimal: '.',
      thousands: ',',
      grouping: [3],
      currency: ['$', ''],
    },
    loadingErrorTitle: 'The request did not complete',
    loadingErrorSubtitle: 'Please try again later',
    noDataTitle: 'No data available',
    noDataSubtitle: 'Please try again later',
  },
  de: {
    population: 'Einwohner: ',
    search: 'Suche...',
    thousandsSeparator: '.',
    taglineMore: 'weitere',
    taglineNoData: 'Keine Daten verfügbar',
    cityChartYAxisLabel: 'Anzahl an Jobs',
    dateFormat: '%d.%m.%Y',
    numFormat: {
      decimal: ',',
      thousands: '.',
      grouping: [3],
      currency: ['', '\u00a0€'],
    },
    loadingErrorTitle: 'Bei der Anfrage ist ein Fehler aufgetreten',
    loadingErrorSubtitle: 'Bitte versuchen Sie es später nochmal',
    noDataTitle: 'Keine Daten verfügbar',
    noDataSubtitle: 'Bitte versuchen Sie es später nochmal',
  },
};

class I18n {
  static t(path) {
    return translations[window.locale][path];
  }
}

export default I18n;
