export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const arrayInGroupsOf = (array, n) => {
  const result = [];

  array.forEach((el, i) => {
    if (i % n === 0) result.push([]);
    result[result.length - 1].push(el);
  });

  return result;
};

export const formatBigNum = (num, sep) => num.toString()
  .split('')
  .reverse()
  .reduce((acc, curr, i) => {
    if (i !== 0 && i % 3 === 0) {
      return acc.concat([sep, curr]);
    }

    return acc.concat([curr]);
  }, [])
  .reverse()
  .join('');
