import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n';
import { formatBigNum } from '../../util';

class City extends React.Component {
  static noTags() {
    return <p className="tagline-no-data">{I18n.t('taglineNoData')}</p>;
  }

  constructor(props) {
    super(props);

    this.state = this.getDatapointsWithScore().reduce((acc, curr) => {
      const newObj = {};

      newObj[curr.name] = {
        border: `1px solid #${curr.background_color}`,
        backgroundColor: 'white',
        color: `#${curr.background_color}`,
      };

      return Object.assign(acc, newObj);
    }, {});

    this.getDatapointsWithScore = this.getDatapointsWithScore.bind(this);
    this.handleTagHoverStart = this.handleTagHoverStart.bind(this);
    this.handleTagHoverEnd = this.handleTagHoverEnd.bind(this);
    this.getMoreTooltip = this.getMoreTooltip.bind(this);
    this.technologyTagLine = this.technologyTagLine.bind(this);
  }

  getDatapointsWithScore() {
    const { city } = this.props;

    return city.datapoints.filter(d => d.score > 0);
  }

  getMoreTooltip() {
    return this.getDatapointsWithScore()
      .slice(City.amountTags)
      .map(dp => dp.name)
      .join('\n');
  }

  handleTagHoverStart(datapoint) {
    return () => {
      const data = this.getDatapointsWithScore().find(d => d.name === datapoint);

      const newState = {};
      newState[datapoint] = {
        border: `1px solid #${data.background_color}`,
        backgroundColor: `#${data.background_color}`,
        color: `#${data.foreground_color}`,
      };

      this.setState(newState);
    };
  }

  handleTagHoverEnd(datapoint) {
    return () => {
      const data = this.getDatapointsWithScore().find(d => d.name === datapoint);

      const newState = {};
      newState[datapoint] = {
        border: `1px solid #${data.background_color}`,
        backgroundColor: 'white',
        color: `#${data.background_color}`,
      };

      this.setState(newState);
    };
  }

  technologyTagLine() {
    const { city } = this.props;

    const datapoints = this.getDatapointsWithScore();

    if (datapoints.length === 0) {
      return City.noTags();
    }

    return (
      <div className="tags">
        {datapoints.slice(0, City.amountTags).map((dp) => {
          const { [dp.name]: style } = this.state;

          return (
            <span
              onMouseEnter={this.handleTagHoverStart(dp.name)}
              onMouseLeave={this.handleTagHoverEnd(dp.name)}
              key={`${city.name}-${dp.name}`}
              className="tag is-medium"
              style={style}
            >
              {dp.name}
            </span>
          );
        })}
        {datapoints.length > City.amountTags ? (
          <span className="tag is-medium tagline-more-data" data-tooltip={this.getMoreTooltip()}>
            {`+ ${datapoints.length - City.amountTags} ${I18n.t('taglineMore')}`}
          </span>
        ) : null}
      </div>
    );
  }

  render() {
    const { city } = this.props;
    const tagLine = this.technologyTagLine();

    return (
      <div className="column">
        <a href={`/cities/${city.id}`}>
          <div className="card">
            <div className="card-image">
              <figure className="image is-square">
                <img src={city.image} alt={city.name} />
              </figure>
            </div>
            <div className="card-content">
              <div className="content">
                <p className="title is-4">{city.name}</p>
                <p className="subtitle is-6">
                  {I18n.t('population')}
                  {formatBigNum(city.population, I18n.t('thousandsSeparator'))}
                </p>
              </div>
            </div>
            <footer className="card-footer">
              <div className="card-footer-item">
                {tagLine}
              </div>
            </footer>
          </div>
        </a>
      </div>
    );
  }
}

City.propTypes = {
  city: PropTypes.object.isRequired, // eslint-disable-line
};

City.amountTags = 2;

export default City;
